import { gql } from '@apollo/client';

export const CREATE_BRANCH = gql`
  mutation CreateBranchMutation($input: CreateBranchInput!) {
    createBranch(input: $input) {
      errors {
        ...ErrorsFragment
      }
      tree {
        id
        identifier
        branches {
          id
          identifier
          path
          children {
            id
            identifier
          }
        }
      }
      branch {
        identifier
        path
      }
    }
  }
`;

export const EDIT_MEASUREMENT = gql`
  mutation EditMeasurementMutation(
    $input: EditMeasurementInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    editMeasurement(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              ...MeasurementFragment
            }
          }
        }
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...ToReviewAggregateMeasurementsFragment
      }
    }
  }
`;

export const REORDER_BRANCH = gql`
  mutation ReorderBranchMutation(
    $input: ReorderBranchInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    reorderBranch(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        # measurements(
        #   startDate: $startDate
        #   endDate: $endDate
        #   states: $states
        # ) {
        #   totalCount
        #   edges {
        #     node {
        #       ...MeasurementFragment
        #     }
        #   }
        # }
      }
    }
  }
`;

export const REORDER_MEASUREMENT = gql`
  mutation ReorderMeasurementMutation(
    $input: ReorderMeasurementInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    reorderMeasurement(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedMeasurements {
        ...MeasurementFragment
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              ...MeasurementFragment
            }
          }
        }
      }
    }
  }
`;

export const SET_MEASUREVIEW_COLUMNS = gql`
  mutation SetMeasureViewColumnsMutation(
    $input: SetMeasureViewColumnsInput!
  ) {
    setMeasureViewColumns(input: $input) {
      errors {
        ...ErrorsFragment
      }
      ok
      user {
        ... on UserObject {
          identifier
        }
      }
      account {
        id
        measureViewColumns
      }
    }
  }
`;

export const CREATE_MEASUREMENT = gql`
  mutation CreateMeasurementMutation(
    $input: CreateMeasurementInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    createMeasurement(input: $input) {
      errors {
        ...ErrorsFragment
      }
      measurement {
        id
        identifier
        name
        branch {
          path
          userPermissions {
            read
            create
            edit
            delete
          }
        }
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              id
              identifier
            }
          }
        }
        # ...AggregateMeasurementsAmountFragment
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...ToReviewAggregateMeasurementsFragment
      }
    }
  }
`;

export const CREATE_MEASUREMENT_FROM_QUERY = gql`
  mutation CreateMeasurementFromQueryMutation(
    $input: CreateMeasurementFromQueryInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    createMeasurementFromQuery(input: $input) {
      errors {
        ...ErrorsFragment
      }
      measurement {
        id
        identifier
        name
        branch {
          path
          userPermissions {
            read
            create
            edit
            delete
          }
        }
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              id
              identifier
            }
          }
        }
        # ...AggregateMeasurementsAmountFragment
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...ToReviewAggregateMeasurementsFragment
      }
    }
  }
`;

export const DELETE_MEASUREMENT = gql`
  mutation DeleteMeasurementMutation(
    $input: DeleteMeasurementInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    deleteMeasurement(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              id
              identifier
              state
            }
          }
        }
        # ...AggregateMeasurementsAmountFragment
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...ToReviewAggregateMeasurementsFragment
        ...PublicAggregateMeasurementsAmountFragment
      }
    }
  }
`;

export const BULK_MEASUREMENT_ACTIONS = gql`
  mutation bulkMeasurementActionsMutation(
    $input: BulkMeasurementActionsInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    bulkMeasurementActions(input: $input) {
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              id
              identifier
              state
              kgCo2e
              scope1
              scope2
              scope3
              scope
              emissionFactor {
                identifier
                name
                rate
              }
            }
          }
        }
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...AggregateMeasurementsAmountFragment
        ...PublicAggregateMeasurementsAmountFragment
        ...ToReviewAggregateMeasurementsFragment
      }
      errors {
        ...ErrorsFragment
      }
      ok
    }
  }
`;

export const BULK_UPDATE_MEASUREMENT_STATE = gql`
  mutation BulkRecordMeasurementsMutation(
    $input: BulkUpdateMeasurementStateInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    bulkUpdateMeasurementState(input: $input) {
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              state
              identifier
              id
            }
          }
        }
        # ...AggregateMeasurementsAmountFragment
        ...PublicAggregateMeasurementsAmountFragment
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...ToReviewAggregateMeasurementsFragment
      }
    }
  }
`;

export const BULK_UPDATE_MEASUREMENTS_EMISSION_FACTOR = gql`
  mutation BulkUpdateMeasurementsEmissionFactorMutation(
    $input: BulkUpdateMeasurementsEmissionFactorInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    bulkUpdateMeasurementsEmissionFactor(input: $input) {
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          edges {
            node {
              id
              identifier
              kgCo2e
              scope1
              scope2
              scope3
              scope
              emissionFactor {
                identifier
                name
                rate
              }
            }
          }
        }
      }
    }
  }
`;

export const BULK_MOVE_MEASUREMENTS_INTO_BRANCH = gql`
  mutation BulkMoveMeasurementsIntoBranchMutation(
    $input: BulkMoveMeasurementsIntoBranchInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    bulkMoveMeasurementsIntoBranch(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              id
              identifier
            }
          }
        }
        ...AggregateMeasurementsAmountFragment
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...PublicAggregateMeasurementsAmountFragment
      }
    }
  }
`;

export const BULK_DELETE_MEASUREMENTS = gql`
  mutation BulkDeleteMeasurementsMutation(
    $input: BulkDeleteMeasurementsInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    bulkDeleteMeasurements(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              id
              identifier
              state
            }
          }
        }
        # ...AggregateMeasurementsAmountFragment
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...ToReviewAggregateMeasurementsFragment
        ...PublicAggregateMeasurementsAmountFragment
      }
    }
  }
`;

export const DELETE_BRANCH = gql`
  mutation DeleteBranchMutation(
    $input: DeleteBranchInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    deleteBranch(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              id
              identifier
              state
            }
          }
        }

        # ...AggregateMeasurementsAmountFragment
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...ToReviewAggregateMeasurementsFragment
        # is needed to update the (number) in the footprint branch row
        ...PublicAggregateMeasurementsAmountFragment
      }
    }
  }
`;

export const TOGGLE_PINNED_BRANCHES = gql`
  mutation TogglePinnedBranchesMutation(
    $input: TogglePinnedBranchesInput!
  ) {
    togglePinnedBranches(input: $input) {
      errors {
        ...ErrorsFragment
      }
      pinnedBranches {
        id
        identifier
        name
        kgCo2e
        isPinned
      }
    }
  }
`;

export const BULK_DELETE_BRANCHES = gql`
  mutation BulkDeleteBranchesMutation(
    $input: BulkDeleteBranchesInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    bulkDeleteBranches(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        # ...AggregateMeasurementsAmountFragment
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...ToReviewAggregateMeasurementsFragment
        ...PublicAggregateMeasurementsAmountFragment
      }
    }
  }
`;

export const MOVE_BRANCH = gql`
  mutation MoveBranchMutation(
    $input: MoveBranchInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    moveBranch(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        ...AggregateMeasurementsAmountFragment
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...ToReviewAggregateMeasurementsFragment
        ...PublicAggregateMeasurementsAmountFragment
      }
    }
  }
`;

export const MOVE_MEASUREMENT_INTO_BRANCH = gql`
  mutation MoveMeasurementIntoBranchMutation(
    $input: MoveMeasurementIntoBranchInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    moveMeasurementIntoBranch(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              id
              identifier
              branch {
                id
                identifier
              }
            }
          }
        }
        ...AggregateMeasurementsAmountFragment
        # is needed to update "Review (number)" in TreeHeaders component
        # is needed to update the list of measurementIds for bulk operations in SummaryPanel component
        ...ToReviewAggregateMeasurementsFragment
        ...PublicAggregateMeasurementsAmountFragment
      }
    }
  }
`;

export const EDIT_BRANCH = gql`
  mutation EditBranchMutation($input: EditBranchInput!) {
    editBranch(input: $input) {
      errors {
        ...ErrorsFragment
      }
      tree {
        branches {
          id
          identifier
          name
          description
        }
      }
    }
  }
`;

export const UPLOAD_BRANCH_ATTACHMENT = gql`
  mutation UploadBranchAttachmentMutation(
    $input: UploadBranchAttachmentInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    uploadBranchAttachment(input: $input) {
      ok
      errors {
        ...ErrorsFragment
      }
      branch {
        ...BranchFragment
      }
    }
  }
`;

export const EDIT_BRANCH_ATTACHMENT = gql`
  mutation EditBranchAttachmentMutation(
    $input: EditBranchAttachmentInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    editBranchAttachment(input: $input) {
      ok
      errors {
        ...ErrorsFragment
      }
      branch {
        ...BranchFragment
      }
      attachment {
        identifier
        isPublic
        isImage
        file {
          name
          size
          url
        }
      }
    }
  }
`;

export const DELETE_BRANCH_ATTACHMENT = gql`
  mutation DeleteBranchAttachmentMutation(
    $input: DeleteBranchAttachmentInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    deleteBranchAttachment(input: $input) {
      ok
      errors {
        ...ErrorsFragment
      }
      branch {
        ...BranchFragment
      }
    }
  }
`;

export const SET_ACTIVE_ENTITY = gql`
  mutation SetActiveEntityMutation($input: SetActiveEntityInput!) {
    setActiveEntity(input: $input) {
      errors {
        ...ErrorsFragment
      }
      entity {
        identifier
        name
        tree {
          identifier
          head {
            identifier
            name
            path
          }
          heads {
            path
            identifier
            name
            permissions {
              identifier
              read
              create
              edit
              delete
              branch {
                identifier
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_GOAL = gql`
  mutation CreateGoalMutation($input: CreateGoalInput!) {
    createGoal(input: $input) {
      errors {
        ...ErrorsFragment
      }
      goal {
        ...GoalFragment
      }
      tree {
        id
        identifier
        branches {
          id
          identifier
          goal {
            ...GoalFragment
          }
        }
      }
    }
  }
`;

export const EDIT_GOAL = gql`
  mutation EditGoalMutation($input: EditGoalInput!) {
    editGoal(input: $input) {
      errors {
        ...ErrorsFragment
      }
      tree {
        id
        identifier
        branches {
          id
          identifier
          goal {
            ...GoalFragment
          }
        }
      }
    }
  }
`;

export const GENERATE_OR_REFRESH_GOAL_RECOMMENDATIONS = gql`
  mutation GenerateOrRefreshGoalRecommendationsMutation(
    $input: GenerateOrRefreshGoalRecommendationsInput!
  ) {
    generateOrRefreshGoalRecommendations(input: $input) {
      ok
      errors {
        authorizationError {
          message
        }
      }
      tree {
        id
        branches {
          id
          identifier
          goal {
            ...GoalFragment
          }
        }
      }
    }
  }
`;

export const CREATE_BANK_ACCOUNT_VIA_FLINKS = gql`
  mutation FlinksWidgetMutation(
    $input: PublicCreateBankAccountViaFlinksInput!
  ) {
    createBankAccountViaFlinks(input: $input) {
      errors {
        ...ErrorsFragment
      }
      bankAccount {
        institutionNumber
        accountNumber
        branchNumber
        state
        account {
          user {
            emailAddress
          }
        }
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation subscriptionCancelMutation(
    $input: CancelSubscriptionInput!
  ) {
    cancelSubscription(input: $input) {
      user {
        ... on UserObject {
          ...UserFragment
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const INACTIVATE_SUBSCRIPTION = gql`
  mutation billingInactivateSubscriptionMutation(
    $input: InactivateSubscriptionInput!
  ) {
    inactivateSubscription(input: $input) {
      errors {
        ...ErrorsFragment
      }
      user {
        ...UserFragment
      }
    }
  }
`;

export const ADD_ITEM_TO_CART = gql`
  mutation cartAddItemMutation($input: AddItemToCartMutationInput!) {
    addItemToCart(input: $input) {
      errors {
        ...ErrorsFragment
      }
      cart {
        ...CartFragment
      }
    }
  }
`;

export const REMOVE_ITEM_FROM_CART = gql`
  mutation cartRemoveItemMutation(
    $input: RemoveItemFromCartMutationInput!
  ) {
    removeItemFromCart(input: $input) {
      errors {
        ...ErrorsFragment
      }

      cart {
        ...CartFragment
      }
    }
  }
`;

export const UPDATE_CART_ITEM_AMOUNT = gql`
  mutation cartUpdateItemAmountMutation(
    $input: UpdateCartItemAmountMutationInput!
  ) {
    updateCartItemAmount(input: $input) {
      errors {
        ...ErrorsFragment
      }
      cart {
        ...CartFragment
      }
    }
  }
`;

export const BULK_ADD_ITEMS_TO_CART = gql`
  mutation cartAddBulkItemsMutation(
    $project_id: String!
    $amount: Int!
  ) {
    addBulkItemsToCart(
      input: { projectId: $project_id, amount: $amount }
    ) {
      errors {
        ...ErrorsFragment
      }
      cart {
        ...CartFragment
      }
    }
  }
`;

export const CLEAR_CART = gql`
  mutation cartClearMutation($input: ClearCartMutationInput!) {
    clearCart(input: $input) {
      errors {
        ...ErrorsFragment
      }
      cart {
        ...CartFragment
      }
    }
  }
`;

export const CHECKOUT_CART = gql`
  mutation cartCheckoutMutation($input: CheckoutMutationInput!) {
    checkout(input: $input) {
      errors {
        ...ErrorsFragment
      }
      purchase {
        identifier
        amountIn
        amountOut
        assetName
        fromWallet {
          amountTotal
          amountAvailable
        }
        toWallet {
          amountTotal
          amountAvailable
        }
        transactionType
        sales {
          identifier
          amountIn
          amountOut
          assetName
          fromWallet {
            amountTotal
            amountAvailable
          }
          toWallet {
            amountTotal
            amountAvailable
          }
          transactionType
        }
        direction
        assetMetadata {
          name
          fullName
          precision
          exchangeRate
          historical1h {
            timestamp
            exchangeRate
          }
          historical1d {
            timestamp
            exchangeRate
          }
          historical1w {
            timestamp
            exchangeRate
          }
          historical1m {
            timestamp
            exchangeRate
          }
          historical1y {
            timestamp
            exchangeRate
          }
        }
        state {
          identifier
          enum
          reason
        }
        states {
          identifier
          enum
          reason
        }
        history {
          amountTotal
          amountAvailable
          amountReserved
        }
      }
      cart {
        ...CartFragment
      }
    }
  }
`;

export const RUN_SALE_QUEUE = gql`
  mutation cartRunSaleQueueMutation(
    $input: RunSaleQueueMutationInput!
  ) {
    runSale(input: $input) {
      errors {
        ...ErrorsFragment
      }
      done
      numProcessed
    }
  }
`;

export const DELETE_GOAL = gql`
  mutation DeleteGoalMutation($input: DeleteGoalInput!) {
    deleteGoal(input: $input) {
      errors {
        ...ErrorsFragment
      }
      tree {
        id
        identifier
        branches {
          id
          identifier
          goal {
            ...GoalFragment
          }
          goals {
            id
            identifier
          }
        }
      }
    }
  }
`;

export const RESEND_MFA_EMAIL = gql`
  mutation ResendEmailTextMutation($input: ResendMFAEmailInput!) {
    resendMfaEmail(input: $input) {
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const EXCHANGE_MFA_FOR_JWT = gql`
  mutation VerifyCodeViewExchangeMFAForJWTMutation(
    $input: ExchangeMFAForJWTInput!
  ) {
    exchangeMfaForJwt(input: $input) {
      payload
      token
      user {
        ... on UserObject {
          identifier
          emailVerified
          entity {
            name
            sector
            companyDescription
            billing {
              ...BillingFragment
            }
          }
        }
        ... on PublicUserObject {
          emailVerified
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const EXCHANGE_PUBLIC_TOKEN = gql`
  mutation ExchangePublicTokenMutation(
    $input: ExchangePublicTokenInput!
  ) {
    exchangePublicToken(input: $input) {
      errors {
        ...ErrorsFragment
      }
      plaidItems {
        ...PlaidItemFragment
      }
    }
  }
`;

export const CREATE_LINK_TOKEN = gql`
  mutation CreateLinkTokenMutation($input: CreateLinkTokenInput!) {
    createLinkToken(input: $input) {
      linkToken
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const REMOVE_PLAID_ITEM = gql`
  mutation RemovePlaidItemMutation($input: RemovePlaidItemInput!) {
    removePlaidItem(input: $input) {
      errors {
        ...ErrorsFragment
      }
      plaidItems {
        ...PlaidItemFragment
      }
    }
  }
`;

export const SIGNUP = gql`
  mutation SignupMutation($input: SignupInput!) {
    signup(input: $input) {
      loginToken
      token
      user {
        ... on UserObject {
          identifier
          emailVerified
          emailAddress
          profile {
            firstName
            lastName
          }
          entity {
            billing {
              ...BillingFragment
            }
          }
        }
        ... on PublicUserObject {
          emailVerified
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const SIGNUP_FROM_INVITE = gql`
  mutation SignupFromInviteMutation($input: SignupFromInviteInput!) {
    signupFromInvite(input: $input) {
      loginToken
      token
      user {
        ... on UserObject {
          identifier
          profile {
            firstName
            lastName
          }
          emailVerified
          entity {
            billing {
              ...BillingFragment
            }
          }
        }
        ... on PublicUserObject {
          identifier
          emailVerified
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const SIGNIN = gql`
  mutation SigninMutation($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        ... on UserObject {
          identifier
          emailVerified
          emailAddress
          profile {
            firstName
            lastName
          }
          activeAccount {
            surveySubmissions {
              survey {
                template
              }
              surveyInvite {
                identifier
              }
            }
          }
          entity {
            name
            sector
            companyDescription
          }
          entity {
            billing {
              ...BillingFragment
            }
          }
        }
        ... on PublicUserObject {
          emailVerified
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfileMutation($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      user {
        ... on UserObject {
          ...UserFragment
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const NOTIFICATIONS_POPOVER_MARK_AS_READ = gql`
  mutation NotificationsPopoverMarkAsReadMutation(
    $input: ReadNotificationsInput!
  ) {
    readNotifications(input: $input) {
      user {
        ... on UserObject {
          ...UserFragment
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePasswordMutation($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      user {
        ... on UserObject {
          ...UserFragment
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPasswordMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const CONFIRM_PASSWORD_RESET = gql`
  mutation ConfirmPasswordResetMutation(
    $input: ConfirmPasswordResetInput!
  ) {
    confirmPasswordReset(input: $input) {
      user {
        ... on UserObject {
          ...UserFragment
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethodMutation(
    $input: UpdatePaymentMethodInput!
  ) {
    updatePaymentMethod(input: $input) {
      user {
        ... on UserObject {
          ...UserFragment
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($input: CreateSubscriptionInput!) {
    createSubscription(input: $input) {
      user {
        ... on UserObject {
          ...UserFragment
        }
      }
      transactionId
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const UPDATE_MEASUREMENT_FACTOR = gql`
  mutation UpdateMeasurementEmissionFactor(
    $input: UpdateMeasurementEmissionFactorInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    updateMeasurementEmissionFactor(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              id
              identifier
              kgCo2e
              scope1
              scope2
              scope3
              scope
              emissionFactor {
                name
                rate
                unspscPath
                unitInfo {
                  __typename
                  ... on UnitInfoEnumObject {
                    unit
                    type
                  }
                }
                reference {
                  source
                  organization
                  country
                  url
                  year
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_MEASUREMENT_BY_FACTOR = gql`
  mutation UpdateMeasurementByEmissionFactor(
    $input: UpdateMeasurementEmissionFactorInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    updateMeasurementEmissionFactor(input: $input) {
      errors {
        ...ErrorsFragment
      }
      affectedBranches {
        ...BranchFragment
        measurements(
          startDate: $startDate
          endDate: $endDate
          states: $states
        ) {
          totalCount
          edges {
            node {
              ...MeasurementFragment
            }
          }
        }
      }
    }
  }
`;

export const COMPLETE_ONBOARDING = gql`
  mutation CompleteOnboardingMutation(
    $input: CompleteOnboardingInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    completeOnboarding(input: $input) {
      errors {
        ...ErrorsFragment
      }
      tree {
        id
        identifier
        branches {
          ...BranchFragment
        }
      }
    }
  }
`;

export const SYNC_ALL_PLAID_ITEM_ACCOUNTS = gql`
  mutation SyncAllPlaidItemAccountsMutation(
    $input: SyncAllPlaidItemAccountsInput!
  ) {
    syncAllPlaidItemAccounts(input: $input) {
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const REMOVE_USER_FROM_GROUP = gql`
  mutation RemoveAccountFromGroupMutation(
    $input: RemoveAccountFromGroupInput!
  ) {
    removeAccountFromGroup(input: $input) {
      errors {
        ...ErrorsFragment
      }
      user {
        ... on UserObject {
          id
          identifier
          emailAddress
        }
      }
    }
  }
`;

/* export const ADD_USER_TO_GROUP = gql`
  mutation AddAccountToGroupMutation($input: AddAccountToGroupInput!) {
    addAccountToGroup(input: $input) {
      errors {
        ...ErrorsFragment
      }
      user {
        ...UserFragment
      }
    }
  }
`; */

/* export const CREATE_GROUP = gql`
  mutation CreateGroupMutation(
    $input: CreateGroupInput!
    $first: Int
    $after: String
    $offset: Int
  ) {
    createGroup(input: $input) {
      errors {
        ...ErrorsFragment
      }
      group {
        ...GroupFragment
      }
    }
  }
`;*/

export const UPLOAD_CSV_FILE = gql`
  mutation GenerateEmissionsFromCsvFileMutation(
    $input: GenerateEmissionsFromCsvFileInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    generateEmissionsFromCsvFile(input: $input) {
      errors {
        ...ErrorsFragment
      }
      tree {
        id
        identifier
        branches {
          ...BranchFragment
        }
      }
    }
  }
`;

export const GENERATE_EMISSIONS_FROM_OCR_FILE_MUTATION = gql`
  mutation FileDropzoneGenerateEmissionsFromFileMutation(
    $input: GenerateEmissionsFromOcrFileInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    generateEmissionsFromOcrFile(input: $input) {
      tree {
        id
        identifier
        branches {
          ...BranchFragment
        }
      }
      errors {
        ...ErrorsFragment
      }
    }
  }
`;

export const UPLOAD_EXCEL_FILE = gql`
  mutation GenerateEmissionsFromExcelFileMutation(
    $input: GenerateEmissionsFromExcelFileInput!
  ) {
    generateEmissionsFromExcelFile(input: $input) {
      errors {
        ...ErrorsFragment
      }
      tree {
        id
        identifier
      }
    }
  }
`;

export const UPLOAD_SUPPORTING_DOCUMENT = gql`
  mutation UploadSupportingDocumentMutation(
    $input: UploadSupportingDocumentInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    uploadSupportingDocument(input: $input) {
      ok
      errors {
        ...ErrorsFragment
      }
      user {
        ...UserFragment
      }
      measurement {
        ...MeasurementFragment
      }
      supportingDocument {
        ...SupportingDocumentFragment
      }
    }
  }
`;

export const DELETE_SUPPORTING_DOCUMENT = gql`
  mutation DeleteSupportingDocumentMutation(
    $input: DeleteSupportingDocumentInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    deleteSupportingDocument(input: $input) {
      ok
      errors {
        ...ErrorsFragment
      }
      user {
        ...UserFragment
      }
      measurement {
        ...MeasurementFragment
      }
    }
  }
`;

export const EDIT_SUPPORTING_DOCUMENT = gql`
  mutation EditSupportingDocumentMutation(
    $input: EditSupportingDocumentInput!
    $startDate: DateTime
    $endDate: DateTime
    $states: [MeasurementStatesEnum]
  ) {
    editSupportingDocument(input: $input) {
      ok
      errors {
        ...ErrorsFragment
      }
      user {
        ...UserFragment
      }
      measurement {
        ...MeasurementFragment
      }
      supportingDocument {
        ...SupportingDocumentFragment
      }
    }
  }
`;
